









import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import CompanyResourceTagRepository from '@/repositories/company/company-resource-tag-repository';
import {CompanyResourceTagType} from "@/entities/company-entity";
import {i18n} from "@/bootstraps/locale";

@Component
export default class TagSelect extends Vue {
  @Prop({required: true}) private value!: string[];
  @Prop({required: true}) private companyId!: number;
  @Prop({required: true}) private tagType!: CompanyResourceTagType;
  @Prop({default: i18n.tc('タグ', 1)}) private label!: string;
  @Prop({default: i18n.t('この規格書の管理用のタグを設定すると、検索や整理などが効率よくできます。')} ) private helpText!: string;
  @Prop({default: i18n.t('選択または自由入力で作成')}) private placeholder!: string;

  protected tagList: string[] = [];
  protected selectedTag: string[] = [];
  protected isLoading: boolean = true;

  mounted() {
    this.selectedTag = this.value;
  }

  @Watch('selectedTag', {deep: true})
  private changeSelectedTag(tags) {
    this.$emit('input', tags);
  }

  private created() {
    (new CompanyResourceTagRepository(this.companyId)).all(this.tagType).then(res => {
      this.tagList = res;
      this.isLoading = false;
    });
  }
}
