





























































import {Component, Prop, Vue} from 'vue-property-decorator';

import LoadingHandler from "@/utils/loading-handler";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import {IFileDefaultValue} from "@/contracts/i-file";
import IngredientRepository, {IngredientListType} from "@/repositories/company/ingredient-repository";
import {
  castIfColumnTypeNumber,
  ColumnSetting,
  ColumnType,
  ImportCsvUtil,
  isColumnTypeNumber
} from "@/components/mixins/import-csv-util";
import stringifyCsv from "csv-stringify/lib/es5";
import * as FileSaver from "file-saver";
import {BOM} from "@/utils/bom-utils";
import {ReadAsType} from "@/components/Form/OnFileSelectedHandler";

@Component({
  components: {SimpleFormItem}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private value!:boolean;

  private get visible() { return this.value; }
  private set visible(val) { this.$emit('input', val); }

  private readonly ReadAsType = ReadAsType;
  private readonly isColumnTypeNumber = isColumnTypeNumber;

  private file = IFileDefaultValue;
  private errors:string[] = [];

  private importCsvUtil = new ImportCsvUtil([
    { prop: 'companyOrd', header: '原材料ID*', type: ColumnType.IntUnsigned, required: true },
    { prop: 'intraCode', header: '自社管理コード', type: ColumnType.String, nullable: true },
    { prop: 'name', header: '原材料名（識別名）', type: ColumnType.String, nullable: true },
    { prop: 'costPerKg', header: '1kg当たり単価（円）', type: ColumnType.DecimalUnsigned, nullable: true },
    { prop: 'yieldPercent', header: '可食率（%）', type: ColumnType.DecimalUnsigned, nullable: true },
  ]);

  private dataList:any[] = [];
  private ownedIngredientList: IngredientListType[] = [];

  private async created() {
    this.ownedIngredientList = await this.getList();
  }

  private async getList(): Promise<IngredientListType[]> {
    return await LoadingHandler(async () => {
      const listAll = await (new IngredientRepository(this.companyId)).list();
      return listAll.filter(l => !l.isPreproduct);
    });
  }

  private async downloadSampleCsv() {
    const options = {
      header: true,
      columns: this.importCsvUtil.columnSettings.map(setting => setting.header),
    };
    const rows = this.ownedIngredientList.map((row: IngredientListType) => {
      return [ row.companyOrd, row.intraCode, row.name, row.costPerKg, row.yieldPercent ? row.yieldPercent: null ];
    });
    stringifyCsv(rows, options, (err, output) => {
      if (err) throw err;
      if (output) {
        FileSaver.saveAs(new Blob([BOM, output], {type: "text/csv;charset=utf-8" } ), "原材料情報の一括上書き用.csv");
      }
    });
  }

  private async onCsvChanged(data: { filename: string, body: ArrayBuffer | null }) {
    this.dataList = [];

    if (!data.body) {
      this.errors = [];
      return;
    }

    const dataList = this.importCsvUtil.parseTextToColumns(data.body) || [];
    if (!dataList) {
      return;
    }
    if (!dataList.length) {
      this.errors.push('CSVファイルにデータがありません。');
      return;
    }

    this.errors = this.importCsvUtil.easyValidateCsv(dataList);
    if (this.errors.length) return;

    this.errors = await this.validate(dataList);
    if (this.errors.length) return;

    this.dataList = dataList;
  }

  private async validate(dataList): Promise<string[]> {
    const listIds = this.ownedIngredientList.map(l => l.companyOrd);
    const dataNotExistInDB = dataList.filter((d:{[key:string]: any}) => !listIds.includes(d['原材料ID*']));
    return dataNotExistInDB.map(d => `原材料ID「${d['原材料ID*']}」が見つかりませんでした。IDを再確認してください。`);
  }

  private findIngredient(dataRow) {
    return this.ownedIngredientList.find(i => i.companyOrd === dataRow['原材料ID*'])!;
  }
  private isValChanged(dataRow, setting: ColumnSetting): boolean {
    if (!setting.prop) return false;
    if (dataRow[setting.header] === undefined) return false;

    const targetIng = this.findIngredient(dataRow);
    if (!targetIng) return false;

    const val = (dataRow[setting.header] === null || dataRow[setting.header] === '') ?
      null :
      castIfColumnTypeNumber(setting.type, dataRow[setting.header]);
    return targetIng[setting.prop] != val;
  }
  private get updatingRowCount(): number {
    return this.dataList.filter((dataRow) => {
      return this.importCsvUtil.columnSettings.some(s => this.isValChanged(dataRow, s));
    }).length;
  }

  private async importCsv() {
    if (!this.dataList.length) return;

    LoadingHandler(async () => {
      const res = await (new IngredientRepository(this.companyId)).bulkImport(this.dataList);
      this.$message({
        type: 'success',
        message: this.$t(`上書き成功。該当の原材料の情報が上書きされました。（対象: ${res.counts.target}件、更新: ${res.counts.updated}件）`)}
      );
    })
      .then(async () => {
        this.$emit('imported');
        this.dataList = [];
        this.visible = false;
      });
  }
}

