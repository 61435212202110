export const accessibilityToOtherDeptResources =
  `如果启用了部门管理，你可以在部门之间设置数据的查看和编辑权限。

如果 "管理 "被设置为会员所属的部门，不管这个设置如何，如果会员是 "编辑会员"，可以查看和编辑所有数据，如果是 "查看会员"，可以查看所有数据。

- 所有数据都可以被查看和编辑

无论你属于哪个部门，你都可以查看所有的数据，如果你是一个编辑成员，你可以编辑它。

- 所有数据都可以被查看，但非会员不能编辑

无论你属于哪个部门，你都可以查看所有数据。 然而，你不能编辑你不属于的部门的数据，即使你是一个 "编辑成员"。

- 你的部门以外的数据可以被隐藏，无法编辑和引用

隐藏你不属于的部门以外的数据。 然而，它可以从直接的URL中查看和引用。 即使你是 "编辑成员"，你也不能编辑数据。

- 你所在部门以外的数据不能被查看、编辑或引用

不能查看来自你自己部门以外的数据。 你不能引用其他部门的数据。 即使你是一个 "编辑成员"，你也不能编辑数据。`.replace(/\n/g, '<br>');

export const memberDepartments =
  `你可以设置该成员所属的部门。

通过设置成员所属的部门以及规格和食品标签，可以限制其他部门的查看。

如果部门功能为ON，至少要设置一个部门。

注：关于 "管理"。

管理部门 "的成员可以查看所有的标准和产品，与其他部门无关。

此外，管理成员被强制分配到 "管理"部门，不能被免职。`.replace(/\n/g, '<br>');

export const resourceDepartments =
`请选择管理该数据的部门

如果不指定，只有管理成员可以查看。

要检查和设置会员属于哪个部门，管理会员应进入会员名称>组织账户设置>添加或管理会员，在屏幕的右上角。`.replace(/\n/g, '<br>');

export const reconfirmationNotification =
`当标准更新后过了一定的时间，就会发出通知，以检查规格是否是最新的。

用作依据的日期可以从以下方面选择

- 最后一次更新日期（通过智能食品规格收到的最后一次规格交付日期，如果是手工操作，则是不包括管理信息的最后一次标准修订日期）
- 应用日期（规格内容生效的日期）`.replace(/\n/g, '<br>');
