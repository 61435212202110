

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private value!: number[];

  private selectedDepartmentIds: number[] = [];

  private departmentSelections: { id: number; name: string; }[] = [];

  private readonly ManagementTagId = -1;

  private created() {
    this.departmentSelections =
      [{ id: this.ManagementTagId, name: this.$t('spec.common.management_tag'),}].concat(this.company.departments.map(d => ({id: d.id, name: d.name})));
    this.selectedDepartmentIds = [this.ManagementTagId ].concat(this.value);
  }

  private onDepartmentSelected(val:number[]) {
    this.$emit('input', val.filter(d => d !== this.ManagementTagId));
  }
}
