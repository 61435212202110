













import {Component, Vue, Prop} from 'vue-property-decorator';

import {CropTypes} from "@/entities/ingredient-item-gmo-entity";
import {ValidatorRules} from "@/entities/ingredient-item-gmo-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private value!:any;
  @Prop({default: false}) private clearable!:boolean;
  @Prop({required: true}) private formItemProp!:string;
  @Prop({default: false}) private disabled!:boolean;

  private get d_value() { return this.value; }
  private set d_value(val) { this.$emit('input', val); }

  private cropTypes = CropTypes;
  private rules = ValidatorRules.cropType;
}
