import {Component, Prop, Watch} from "vue-property-decorator";
import VueScrollTo from 'vue-scrollto';
import LoadingHandler from '@/utils/loading-handler';
import {ValidateWithScroll} from "@/utils/validation-handler";
import RepositoryBase from "@/repositories/repository-base";
import CreatePageBase from "@/views/label/companies/CreatePageBase";
import execWithLoading from "@/utils/loading-handler";
import IngredientRepository from "@/repositories/company/ingredient-repository";
import ProductRepository from "@/repositories/company/product-repository";

@Component
export default class CreateLabelPageBase extends CreatePageBase {
  @Prop({required: false}) public id?:number;

  protected model:any|null;

  protected ctor!:new() => any;
  protected titleLabel!:string;

  public isAllLoaded:boolean = false;

  // Handle Cancel
  public isAttachmentsInitialized = false;
  public isForwardingProgrammatically = false;

  protected routePrefix!:string;

  @Watch('model', {deep: true})
  private watchModel(val, oldVal) {
    this.onDataChanged(val, oldVal);
  }

  protected get isCreateMode(): boolean {
    return this.$route.name !== `${this.routePrefix}.edit`;
  }
  protected get isCloneMode(): boolean {
    return this.$route.name === `${this.routePrefix}.clone`;
  }
  protected onDataChanged(val, oldVal) {
    if(this.isAllLoaded && this.isAttachmentsInitialized) {
      this.isAnyChanged = true;
    }
  }

  public async initializeModel(): Promise<any> {
    if (this.isCloneMode) {
      const model = await this.findById(this.getId()!);
      if (model) {
        model.id = undefined as any;
        model.name += " のコピー";
        model.createdAt = null as any;
        model.updatedAt = null as any;
      }
      return model;
    } else if (this.isCreateMode) {
      return new this.ctor();
    } else {
      return await this.findById(this.getId()!);
    }
  }

  protected findById(id) {
    return this.getRepository().findById(id);
  }
  protected getRepository():IngredientRepository | ProductRepository { return null as any;} ;
  protected getId():number|undefined { return this.id } ;

  public async submitData(data) {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return false;

    const modeLabel = this.$t(this.isCreateMode ? "作成" : "更新");

    LoadingHandler(async () => {
      return await this.isCreateMode ? this.getRepository().create(data) : this.getRepository().update(this.getId(), data);
    })
      .then(() => {
        this.isForwardingProgrammatically = true;
        this.$router.push({name: `${this.routePrefix}.index`}, () => {
          this.$message({type: 'success', message: `${this.titleLabel}「${this.model!.name}」を${modeLabel}しました`});
        });
      })
      .catch(error => {
        VueScrollTo.scrollTo(document.body);
        throw error;
      });
  }

  private async toggleLock() {
    if (this.isCreateMode) return;

    const confirm = async (msg, title) => {
      return new Promise((resolve, reject) => {
        this.$confirm(msg, title, {
          confirmButtonText: this.$t('はい'),
          confirmButtonClass: 'c-button primary-inverse',
          cancelButtonText: this.$t('キャンセル'),
          cancelButtonClass: 'c-button primary',
        }).then(() => {
          resolve(true);
        }).catch(err => {
          if (err === 'cancel' || err === 'close') {
            resolve(false);
          } else {
            reject(err);
          }
        });
      });
    };

    if(this.model.locked) {
      const msg = this.$t('共有メモを前回の保存時から変更している場合は、保存されません。本当にロック解除しますか。');
      const title = this.$t('ロック解除');
      if (await confirm(msg, title)) {
        await execWithLoading(async () => {
          await this.getRepository().unlock(this.id!);
        });
        this.$message( {type: 'info', message: this.$t('ロックを解除しました。画面を再読み込みします') });
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    } else {
      const msg = this.$t('ロックをすると前回の保存内容からの変更は保存されません。本当にロックしますか。');
      const title = this.$t('ロック');
      if (await confirm(msg, title)) {
        await execWithLoading(async () => {
          await this.getRepository().lock(this.id!);
        });
        this.$message( {type: 'info', message: this.$t('ロックしました。画面を再読み込みします') });
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    }
  }
}
