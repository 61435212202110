export function bufferToString(buf) {
  const func = (b) => String.fromCharCode.apply('', new Uint16Array(b) as any);
  const tmp: any[] = [];
  const len = 1024;
  for (let p = 0; p < buf.byteLength; p += len) {
    tmp.push(func(buf.slice(p, p + len)));
  }
  return tmp.join('');
}

export function removeBom(str: string) {
  return str.replace(/^\uFEFF/gm, '').replace(/^\u00BB\u00BF/gm, '');
}

export const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
