<template>
  <div class="c-summary-row as-flex">
    <div class="c-summary-cell bold">
      <div class="c-list-button c-list-button--plus small"
           :class="{disabled: disabled}"
           @click="(!disabled && $listeners.plus) ? $listeners.plus() : null" cy="addIngredientItem"></div>
    </div>
    <div class="c-summary-cell bold">
      <p class="amount-summary">
        <template v-if="isPercent">
          {{ $t('原材料と添加物の合計割合') }}：<span class="amount-summary-num">{{amount | round(2) | toLocale}} %</span>
        </template>
        <template v-else>
          {{ $t('原材料と添加物の合計重量') }}：<span class="amount-summary-num">{{amount | round(3) | toLocale}} g</span>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isPercent: Boolean,
      amount: Number,
      disabled: {
        type: Boolean,
        default: false
      },
    }
  }
</script>
