






















































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import IngredientEntity, {ValidatorRules} from "@/entities/ingredient-entity";

import { help as PopoverText } from '@/lang/help/ingredient-create';
import IngredientMadeInAreaEntity, {MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";
import MadeInAreaRepository from "@/repositories/master/made-in-area-repository";

@Component({
  components: {}
})
export default class extends Vue {
  private PopoverText = PopoverText;
  private MADEIN_AREA_MAX_COUNT:number = 11;

  @Prop({required: true}) private model!:IngredientEntity;
  @Prop({required: true}) private madeInAreaCount!:number;
  @Prop({required: true}) private isAsProcessed!:boolean;
  @Prop({default: false}) private disabled!:boolean;

  private madeInAreas: MadeInAreaEntity[] = [];

  private selection:any = [];

  public set c_madeInAreaCount(val:number) { this.$emit('update:made-in-area-count', val); }
  public get c_madeInAreaCount():number { return this.madeInAreaCount; }

  @Watch("isAsProcessed")
  private async onTypeChanged() {
    this.initSelection();
  }

  private created() {
    (new MadeInAreaRepository).findAllWithCache().then((list:MadeInAreaEntity[]) => {
      this.madeInAreas = list;
      this.initSelection();
    });

    this.initCount();
  }

  public initCount() {
    // 原産地の前処理
    this.c_madeInAreaCount = this.model.ingredientMadeInAreas.length;

    // madeInAreaCountを減らしても、もとの値が消えないように、
    // MADEIN_AREA_MAX_COUNT まで空オブジェクトをFillする
    const length = this.model.ingredientMadeInAreas.length;
    for(let i = 0; i < this.MADEIN_AREA_MAX_COUNT - length - 1; i++) {
      this.model.ingredientMadeInAreas.push(new IngredientMadeInAreaEntity());
    }
  }

  private initSelection() {
    const extract = (a:MadeInAreaEntity) => ({
      value: (a.isJapanDomestic ? 'domestic-' : 'foreign-') + a.id.toString(),
      label: a.getLabelDisplayName(this.model.isAsProcessed),
      searchKey: a.searchKey,
    });

    // TODO: LANG
    const selection:any = [];
    selection.push({value: '国内', label: '国内', searchKey: '国内', isHeading: true});
    selection.push({value: 'domestic-null', label: '国内（自由入力）', searchKey: '国内（自由入力）'});
    selection.push(...this.madeInAreas.filter(a => a.isJapanDomestic).map(extract));
    selection.push({value: '海外', label: '海外', searchKey: '海外', isHeading: true});
    selection.push({value: 'foreign-null', label: '海外（自由入力）', searchKey: '海外（自由入力）'});
    selection.push(...this.madeInAreas.filter(a => !a.isJapanDomestic).map(extract));
    this.selection = selection;
  }

  private get rules(){
    return ValidatorRules;
  }
}
