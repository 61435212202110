import RepositoryBase from "@/repositories/repository-base";
import {CompanyResourceTagType} from "@/entities/company-entity";

export default class CompanyResourceTagRepository extends RepositoryBase<string[]> {
  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/tags`
  }

  public async all(type: null | CompanyResourceTagType): Promise<string[]> {
    const res = await super.get(`${this.endpoint}?type=${type}`)
    return res.data;
  }
}
