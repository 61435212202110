












import { Component, Vue, Prop } from 'vue-property-decorator';
import IngredientRepository from "@/repositories/company/ingredient-repository";
import ProductRepository from "@/repositories/company/product-repository";
import AuditEntity from "@/entities/audit-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private modelId!:number;
  @Prop({required: true}) private repository!:IngredientRepository|ProductRepository;

  private audits:AuditEntity[] = [];

  private initialized = false;

  private async created() {
    this.repository.getAuditLogs(this.modelId).then(data => {
      this.audits = data.sort((a:AuditEntity, b:AuditEntity) => b.date.getTime() - a.date.getTime());
      this.initialized = true;
    });
  }

}
