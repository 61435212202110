<template>
  <div class="el-form--label-top">
    <div class="el-form-item" :class="[{ 'is-error': !!error, 'is-required': required, } ]">
      <label class="el-form-item__label" v-if="label || $slots.label">
        <slot name="label">{{ label }}</slot>
      </label>
      <div class="el-form-item__content">
        <slot></slot>
        <div v-if='error' class="el-form-item__error" :class="{'el-form-item__error--inline': inlineMessage }">{{error}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SimpleFormItem',

  props: {
    label: String,
    required: {
      type: Boolean,
      default: undefined
    },
    error: String,
    inlineMessage: { type: [Boolean], default: false},
  },
};
</script>
