

















import {Component, Prop, Vue} from "vue-property-decorator";
import {IHasAdditive} from "@/entities/ingredient-additive-entity";
import IngredientEntity from "@/entities/ingredient-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private row!:IHasAdditive;
  @Prop({default: false}) private disabled!:boolean;

  private getSelection() {
    if(!this.row.additive) {
      return [];
    }

    const purposeSynonyms = this.row.additive.selectableAdditivePurposeSynonyms;
    const selection: { value: number|null, label: string, isHeading?: boolean }[] = [];
    selection.push({value: -1, label: this.$t('主な用途'), isHeading: true});
    selection.push(...purposeSynonyms.filter(ps => ps.typical).map(ps => ({value: ps.id, label: ps.synonym })));
    selection.push({value: -2, label: this.$t('その他の用途'), isHeading: true});
    selection.push(...purposeSynonyms.filter(ps => !ps.typical).map(ps => ({value: ps.id, label: ps.synonym })));
    return selection;
  }
}
