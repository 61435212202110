









import { Component, Vue, Prop } from 'vue-property-decorator';
import IconLocked from "@/components/Icons/IconLocked.vue";
import IconLock from "@/components/Icons/IconLock.vue";

@Component({
  components: {IconLock, IconLocked}
})
export default class extends Vue {
  @Prop({required: true}) private locked!: boolean;
}
