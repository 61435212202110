import {AuthUser} from "@/libs/auth";
import CompanyEntity from "@/entities/company-entity";
import {AccessibilityToOtherDeptResources} from "@/entities/company-setting-entity";
import CompanyDepartmentEntity from "@/entities/company-department-entity";

export interface IResourceBelongingToDepartment {
  getDepartmentIds(): number[];
}

export class CompanyDepartmentService {
  private user!: AuthUser;
  private company!: CompanyEntity;

  constructor(user: AuthUser, company: CompanyEntity) {
    this.user = user;
    this.company = company;
  }

  private canPassThrough(): boolean {
    return this.user.canAccessAllDeptResources || !this.user.companySetting.isDepartmentEnabled;
  }

  private canUpdateOtherDepartment(): boolean {
    if (this.canPassThrough()) return true;
    return this.company.setting.accessibilityToOtherDeptResources === AccessibilityToOtherDeptResources.Editable;
  }
  private isUserBelongsToResourceDepartment(resource:IResourceBelongingToDepartment): boolean {
    const resourceDepartmentIds = resource.getDepartmentIds();
    return this.user.departments.some(cd => resourceDepartmentIds.includes(cd.id));
  }

  public canUpdate(resource:IResourceBelongingToDepartment) {
    if (this.canUpdateOtherDepartment()) return true;
    return this.isUserBelongsToResourceDepartment(resource);
  }

}
