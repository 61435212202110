





























import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

import {requiredOnChangeRule} from "@/utils/validation-rules";
import {ValidateWithScroll} from "@/utils/validation-handler";
import LoadingHandler from "@/utils/loading-handler";
import SpecAcceptanceRepository, {
  IAcceptanceWithLatestSpec
} from "@/repositories/spec/company/spec-acceptance-repository";

@Component
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private value!:boolean;

  private acceptances:IAcceptanceWithLatestSpec[]|null = null;

  private importSpecRule = {
    acceptanceId: [requiredOnChangeRule]
  }
  private importSpecModel:{acceptanceId:number|null} = {
    acceptanceId: null
  }

  private get visible() { return this.value; }
  private set visible(val) { this.$emit('input', val); }

  private created() {
    if(this.$auth.user.hasValidSpecSubscription) {
      new SpecAcceptanceRepository(this.companyId).listLatestSpecsOnlyAccepted().then(list => {
        this.acceptances = list;
      });
    }
  }

  private async importSpec() {
    if (!(await ValidateWithScroll(this.$refs.importForm as any))) return;

    LoadingHandler(async () => {
      await new SpecAcceptanceRepository(this.companyId).createIngredient(this.importSpecModel.acceptanceId!);
      this.$emit('imported');
    })
      .then(async () => {
        this.$message({type: 'success', message: this.$t(`規格書から原材料を取り込みました`)});
        this.importSpecModel.acceptanceId = null;
        this.visible = false;
      });
  }
  // endregion Import From Spec
}

