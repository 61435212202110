<template>
  <div class="sticky-header__wrapper">
    <transition name="el-fade-in">
      <div class="sticky-header" :class="headerClass" ref="header" v-bind="$attrs">
        <slot :sticky="showNavbar"/>
      </div>
    </transition>
  </div>
</template>

<script>
  let cloneNode = null;
  export default {
    props: {
      headerClass: {
        type: String,
        default: '',
      },
      startOffset: {
        type: Number,
        default: 0,
      },
      keepNode: { // がくってならないように
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showNavbar:false
      }
    },
    watch: {
      showNavbar() {
        this.showNavbar ? this.attach() : this.detach();
      }
    },
    methods:{
      clone() {
        cloneNode = this.$refs.header.cloneNode(true);
      },
      handleScroll (event) {
        this.showNavbar = window.pageYOffset > this.startOffset;
      },
      attach() {
        this.$refs.header.classList.add('sticky');

        if (this.keepNode) {
          this.$el.appendChild(cloneNode);
        }

        document.body.appendChild(this.$refs.header);
      },
      detach() {
        this.$refs.header.classList.remove('sticky');

        this.$el.appendChild(this.$refs.header);

        if (this.keepNode) {
          if (this.$el.contains(cloneNode)){
            this.$el.removeChild(cloneNode);
          }
        }
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
      this.clone();
    },
    beforeDestroy() {
      if (this.showNavbar){
        this.detach();
      }
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    }
  };
</script>

<style lang="scss" scoped>
  .sticky-header {
    background-color: white;
    &.sticky {
      @extend %fade-in;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $ZINDEX_STICKY;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    }
  }
</style>
