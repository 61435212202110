
























import {Component, Prop, Vue} from "vue-property-decorator";
import {
  TAdditiveSynonymListItem
} from "@/repositories/master/additive-repository";

@Component
export default class extends Vue {
  @Prop({required: true}) private value!:number; // synonymId
  @Prop({required: true}) private selection!:TAdditiveSynonymListItem[];

  @Prop({default: ''}) private alert!:string;
  @Prop({default: 'el-icon-warning danger'}) private alertIconClass!:string;
  @Prop({default: 'small'}) private size!:string;
  @Prop({default: false}) private clearable!: boolean;

  @Prop({default: false}) private disabled!:boolean;

  private synonymId:number = 0;

  private created() {
    this.synonymId = this.value;
  }

  private onSelected(selected:number) {
    this.$emit('input', selected);
    this.$emit('change', this.selection.find(s => s.synonymId === selected)!);
  }
  private getItem(item:TAdditiveSynonymListItem): TAdditiveSynonymListItem {
    return item;
  }
}
