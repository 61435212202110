import RepositoryBase from "@/repositories/repository-base";
import {
  SpecAcceptanceEntity,
  SpecAcceptanceSpecDetailEntity,
  SpecAcceptanceSpecEntity
} from "@/entities/specs/spec-acceptance-entity";
import IngredientEntity from "@/entities/ingredient-entity";
import {ExportEventArgs, ExportValidationResponse, IExportRequest} from "@/repositories/spec/company/spec-repository";
import AuditEntity from "@/entities/audit-entity";
import {AxiosResponse} from "axios";
import SpecAcceptanceIntraCustomValues from "@/entities/specs/spec-acceptance-intra-custom-values";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import SpecEntity from "@/entities/specs/spec-entity";

export interface IAcceptanceWithLatestSpec {
  specId: number,
  name: string,
  search: string,
  createdAt:Date;
  acceptanceId:number;
  acceptanceSpecId:number;
}

export default class SpecAcceptanceRepository extends RepositoryBase<any> {
  public constructor(companyId) {
    super();
    this.endpoint = `spec/companies/${companyId}/for-receiver/`;
  }

  public async findAcceptance(specAcceptanceId:number): Promise<SpecAcceptanceEntity> {
    const res = await this.get(this.endpoint + 'acceptances/' + specAcceptanceId);
    return new SpecAcceptanceEntity(res.data);
  }

  public async updateAcceptanceInfo(specAcceptanceId:number, params:IAcceptanceInfoUpdateRequest) {
    return this.put(this.endpoint + 'acceptances/' + specAcceptanceId, params);
  }
  public async clearUpdateNotification(specAcceptanceId:number) {
    await this.delete(this.endpoint + 'acceptances/' + specAcceptanceId + '/update-notifications');
  }
  public async clearReconfirmNotification(specAcceptanceId:number) {
    await this.delete(this.endpoint + 'acceptances/' + specAcceptanceId + '/reconfirm-notifications');
  }

  public async listLatestSpecs(): Promise<IAcceptanceWithLatestSpec[]>  {
    const res = await this.get(this.endpoint + 'acceptances/list-latest-specs');
    return res.data.map(d => ({ ...d, search: d.name + d.nameKana + d.intraCode }));
  }
  public async listLatestSpecsOnlyAccepted(): Promise<IAcceptanceWithLatestSpec[]>  {
    const res = await this.get(this.endpoint + 'acceptances/list-latest-specs?only_accepted');
    return res.data.map(d => ({ ...d, search: d.name + d.nameKana + d.intraCode }));
  }
  public async findLatestSpecOnlyAccepted(specAcceptanceId:number): Promise<IAcceptanceWithLatestSpec>  {
    const res = await this.listLatestSpecsOnlyAccepted();
    return res.find(s => s.acceptanceId === specAcceptanceId)!;
  }

  public async getAuditLogs(specAcceptanceId:number): Promise<AuditEntity[]> {
    const res = await this.get(this.endpoint + `acceptances/${specAcceptanceId}/audits`);
    return res.data.map(a => new AuditEntity(a));
  }

  public async createIngredient(specAcceptanceId:number) {
    return await this.post(`${this.endpoint}acceptances/${specAcceptanceId}/ingredient`);
  }
  public async listAcceptanceSpecs(specAcceptanceId:number): Promise<SpecAcceptanceSpecEntity[]>  {
    const res = await this.get(`${this.endpoint}acceptances/${specAcceptanceId}/acceptance-specs`);
    return res.data.map(d => new SpecAcceptanceSpecEntity(d));
  }
  public async listAcceptanceSpecsOnlyAccepted(specAcceptanceId:number): Promise<SpecAcceptanceSpecEntity[]>  {
    const res = await this.get(`${this.endpoint}acceptances/${specAcceptanceId}/acceptance-specs?only_accepted`);
    return res.data.map(d => new SpecAcceptanceSpecEntity(d));
  }

  public async makeIngredient(specAcceptanceSpecId:number) {
    const res = await this.get(`${this.endpoint}acceptance-specs/${specAcceptanceSpecId}/ingredient`);
    return new IngredientEntity(res.data);
  }
  public async findAcceptanceSpec(specAcceptanceSpecId:number): Promise<SpecAcceptanceSpecDetailEntity> {
    const res = await this.get(this.endpoint + `acceptance-specs/${specAcceptanceSpecId}`);
    return new SpecAcceptanceSpecDetailEntity(res.data);
  }

  public async validateExportSpec(specAcceptanceSpecId:number, req:ExportEventArgs): Promise<ExportValidationResponse> {
    const res = await super.get(`${this.endpoint}acceptance-specs/${specAcceptanceSpecId}/report/${req.type}/${req.id}/validate`);
    return {
      success: res.data.success,
      error: res.data.error ? new SpecImExportError(res.data.error) : undefined,
    }
  }
  public async exportReceivedSpec(specAcceptanceSpecId:number, req:IExportRequest): Promise<AxiosResponse> {
    return await super.get(`${this.endpoint}acceptance-specs/${specAcceptanceSpecId}/report/${req.type}/${req.id}`, {
      arg1: req.arg1,
      format: req.outputFormat,
      pitsOption: req.pitsOption
    }, {
      responseType: 'blob'
    });
  }

  public async acceptSpec(specAcceptanceSpecId:number): Promise<{isIngredientCreationSkippedByAmountHidden: boolean}> {
    const res = await this.put(this.endpoint + 'acceptance-specs/' + specAcceptanceSpecId + '/accept');
    const isIngredientCreationSkippedByAmountHidden = res.data;
    return {
      isIngredientCreationSkippedByAmountHidden:  isIngredientCreationSkippedByAmountHidden
    };
  }

  public async createForwardingSpec(specAcceptanceId:number): Promise<SpecEntity> {
    const res = await this.post(this.endpoint + 'acceptances/' + specAcceptanceId + '/forward');
    return new SpecEntity(res.data);
  }
}

export interface IAcceptanceInfoRequest {
  receiverIntraCode:string|null;
  receiverMemo:string|null;
  departmentIds: number[];
  assigneeIds: number[];
  tags: string[],
}
export interface IAcceptanceInfoUpdateRequest extends IAcceptanceInfoRequest {
  intraCustomValues: SpecAcceptanceIntraCustomValues[],
}
