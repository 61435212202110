




















import { Component, Vue, Prop } from 'vue-property-decorator';

import IngredientNutritionEntity from "@/entities/ingredient-nutrition-entity";
import NutritionInput from './NutritionInput.vue';
import ProductNutritionOverrideEntity from "@/entities/product-nutrition-override-entity";

  @Component({
    components: {
      NutritionInput,
    }
  })
  export default class extends Vue {
    @Prop({required: true}) nutrition!:IngredientNutritionEntity | ProductNutritionOverrideEntity;
    @Prop({required: true}) canSwitchNatriumAndSalt!:boolean;
    @Prop({required: true}) lighten!:boolean;
    @Prop({default: 'nutrition.'}) propPrefix!:string;
    @Prop({default: false}) private disabled!:boolean;

  }
