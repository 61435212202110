













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({type: String, default: ''}) private popover!:string;
  @Prop({type: String, default: 'top-start'}) private placement!:string;
  @Prop({type: String, default: 'el-icon-warning-outline'}) private iconClass!:string; //el-icon-warning
  @Prop({type: String, default: 'el-icon-warning'}) private iconClassOnHover!:string; //el-icon-warning

  private isHovering = false;
  private get currentIconClass(): string {
    if (this.iconClassOnHover && this.isHovering) {
      return this.iconClassOnHover;
    } else {
      return this.iconClass;
    }
  }
}
