













































































































































































import { Component, Prop} from 'vue-property-decorator';

import IngredientRepository, {IngredientForIndex} from '@/repositories/company/ingredient-repository';

import InputSearch from '../components/InputSearch.vue';
import {IFindOption} from "@/repositories/repository-base";

import stringifyCsv from 'csv-stringify/lib/es5';
import * as FileSaver from "file-saver";
import ListPageBase from "@/views/label/companies/ListPageBase";
import IngredientTemplateRepository, { IngredientTemplate } from '@/repositories/master/ingredient-template-repository';
import LoadingHandler from "@/utils/loading-handler";

import { help as PopoverText } from '@/lang/help/ingredient-create';
import {BOM} from "@/utils/bom-utils";
import ImportFromSpecModal from "@/views/label/companies/ingredient/components/ImportFromSpecModal.vue";
import BulkImportModal from "@/views/label/companies/ingredient/components/BulkImportModal.vue";
import DepartmentAnnotationOnIndex from "@/views/components/Department/DepartmentAnnotationOnIndex.vue";
import EditableSlot from "@/views/components/Department/EditableSlot.vue";

@Component({
  components: {
    EditableSlot,
    DepartmentAnnotationOnIndex,
    BulkImportModal,
    ImportFromSpecModal,
    InputSearch
  }
})
export default class extends ListPageBase {
  private readonly PopoverText = PopoverText;

  protected fetchIngredientCount:boolean = true;

  private movieDialogVisibility:boolean = false;
  private importDialogVisibility:boolean = false;
  private bulkImportDialogVisibility:boolean = false;

  protected getRepository() {
    return new IngredientRepository(this.companyId);
  }

  protected async find(searchText, opt:IFindOption) {
    return await this.getRepository().search(searchText, opt);
  }

  protected async onCreating() {
    this.routeName = 'ingredient';
    this.searchTemplate();
  }

  private onSearch() {
    if (this.currentSearchText === this.searchText) return;
    this.search();
    this.searchTemplate();
  }

  private searchedTemplates:IngredientTemplate[] = [];
  private templateSearching = false;
  private async searchTemplate() {
    this.templateSearching = true;
    if (this.searchText) {
      this.searchedTemplates = await (new IngredientTemplateRepository()).search(this.searchText);
    } else {
      this.searchedTemplates.splice(0);
    }
    this.templateSearching = false;
  }

  private async addIngredientFromTemplate(template:IngredientTemplate) {
    await LoadingHandler(async () => {
      await (new IngredientRepository(this.companyId)).createFromTemplate(template.id);
      await this.load();
    }).then(() => {
      // TODO: LANG
      this.$message({ type: 'success', message: template.name + "を追加しました。（原産地等は個別に設定してください）", showClose: true, appendTo: null } as any);
    });
  }

  private exportCsv() {
    const options = {
      header: true,
      columns: [
        '原材料ID', '原材料名', '原材料区分', '一般表示名', '単価（1kg当たり）', '可食率（%）', '単価（可食部1kg当たり）',
        '原産地（製造地）', 'アレルゲン',
        '熱量(kcal)', 'たんぱく質(g)', '脂質(g)', '炭水化物(g)', 'ナトリウム(mg)', '食塩相当量(g)',
        '共有メモ', '最終更新日', //'この原材料を使用している中間原材料・商品',
      ]
    };
    const rows = this.selectedRows.map((row: IngredientForIndex) => {
      // const refText =
      //   ((row.referencedProducts.preproducts.length > 0) ? `中間原材料\n${row.referencedProducts.preproducts.map(p => '・' + p.name).join('\n')}` : "") +
      //   ((row.referencedProducts.products.length > 0) ? `商品\n${row.referencedProducts.products.map(p => '・' + p.name).join('\n')}` : '');

      return [
        row.companyOrd, row.name, row.ingredientTypeName, row.displayName, row.costPerKg, row.yieldPercent, row.costPerKgComputed,
        row.madeInAreaNames.join("、"), row.allAllergenNames.join("、"),
        row.nutrition ? row.nutrition.calorie : '', row.nutrition ? row.nutrition.protein : '', row.nutrition ? row.nutrition.lipid : '',
        row.nutrition ? row.nutrition.carb : '', row.nutrition ? row.nutrition.natrium : '', row.nutrition ? row.nutrition.salt : '',
        row.note, row.updatedAt,
      ]
    });
    stringifyCsv(rows, options, (err, output) => {
      if (output) {
        FileSaver.saveAs(new Blob([BOM, output], {type: "text/plain;charset=utf-8" } ), "原材料一覧.csv");
      }
      if (err) throw err;
    });
  }

  public downloadProductsReferencingTheIngredients() {
    const ids = this.selectedRows.map(r => r.id);
    LoadingHandler(async () => {
      const fileName = '選択された原材料を使用している商品.csv';
      await (new IngredientRepository(this.companyId)).downloadProductsReferencingTheIngredients(ids, fileName);
    }, 180000, {text: 'この処理は数十秒〜数分かかる場合があります'});
  }
}
