
























import {Component, Prop, Vue} from 'vue-property-decorator';
import StringUtils from "@/utils/string-utils";

@Component
export default class extends Vue {
  @Prop({required: true}) private value!:any[];
  @Prop({required: true}) private options!:any[];

  @Prop({required: false, default: 'id'}) private valueProp!:string;
  @Prop({required: false, default: 'name'}) private labelProp!:string;

  @Prop({default: false}) private clearable!:boolean;
  @Prop({required: false, default: false}) private isPrerender!:boolean;
  @Prop({default: false}) private disabled!:boolean;

  private get d_model() { return this.value; }
  private set d_model(val) { this.$emit('input', val); }

  private query:string = '';
  private prerender:number = 0; // 初期値があるときにlabelじゃなくてidが表示されてしまうので、それを防ぐ

  // 初期値があるときに表示がバグらないようにする
  private created() {
    if (this.isPrerender) {
      this.prerender = this.calcPrerender();
    }
  }
  private calcPrerender(): number {
    // 初期選択されているvalueのなかで, optionsの中でindexが一番大きいもののindex
    return Math.max(
      ...this.value.map(v => {
        return this.options.findIndex(o => o[this.valueProp] === v);
      })
    ) + 1;
  }

  // Filterされたときに表示がバグらないようにする
  private filter(query:string) {
    this.query = query;
  }
  private get filteredOptions() {
    const needle = StringUtils.toHankakuKanaLower(this.query);
    return this.options.filter(o => {
      return StringUtils.toHankakuKanaLower(o[this.labelProp]).indexOf(needle) > -1;
    });
  }
}
