






















import { Component, Vue, Prop } from 'vue-property-decorator';
import IngredientEntity from "@/entities/ingredient-entity";
import IngredientRepository from "@/repositories/company/ingredient-repository";
import {ReferencedProducts} from "@/repositories/company/ingredient-repository";

  @Component
  export default class extends Vue {
    @Prop({required: true}) private model!:IngredientEntity;

    private referencedProducts: ReferencedProducts|null = null;

    private get isCreating() {
      return !this.model.id;
    }

    private created() {
      if (this.isCreating) return;
      new IngredientRepository(this.model.companyId).getReferencedProducts(this.model.id!).then(res => {
        this.referencedProducts = res;
      });
    }
  }
