















import {Component, Vue, Prop} from 'vue-property-decorator';

import {
  getGmoTypesForSelection,
  GmoCropTypesEnum, GmoTypes,
  GmoTypesEnum
} from "@/entities/ingredient-item-gmo-entity";
import {ValidatorRules} from "@/entities/ingredient-item-gmo-entity";

import groupBy from 'lodash/groupBy';

@Component
export default class extends Vue {
  @Prop({required: true}) private value!:GmoTypesEnum;
  @Prop({required: true}) private formItemProp!:string;
  @Prop({default: false}) private disabled!:boolean;
  @Prop({required: true}) private cropType!:GmoCropTypesEnum;

  private get d_value() {
    return this.value;
  }
  private set d_value(val) {
    this.$emit('input', val);
  }

  private rules = ValidatorRules.gmoType;

  private get selection() {
    const group = groupBy(getGmoTypesForSelection(this.cropType), t => t.group);
    return Object.keys(group).flatMap(groupName => ([
      { key: groupName, option: groupName, isHeading: true },
      ...group[groupName],
    ]));
  }
}
