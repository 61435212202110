import ObjectUtils from "@/utils/object-utils";
import {i18n} from "@/bootstraps/locale";

type IngredientErrorMessage = { level:'alert'|'danger'; msg:string; index: number; }

export default class SpecImExportError {
  public isImport!: boolean;
  public targetSystem!: 'そうけんくん'|'メルクリウス'|'EBase'|string;
  public ingredientErrors: Array<{
    index: number, // 1始まり
    attributes: SpecImExportErrorIngredientAttribute[],
  }> = [];
  public specErrors: SpecImExportErrorAttribute[] = [];

  constructor(init:SpecImExportError) {
    this.isImport = init.isImport;
    this.targetSystem = init.targetSystem;
    this.specErrors = (init.specErrors || []).map(d => new SpecImExportErrorAttribute(d));
    this.ingredientErrors = init.ingredientErrors.map(i => {
      return {
        index :i.index,
        attributes: i.attributes.map(a => new SpecImExportErrorIngredientAttribute(a, this))
      }
    });
  };

  public get ingredientErrorMessagesOnExport(): IngredientErrorMessage[] {
    return this.ingredientErrors.flatMap(i => {
      return i.attributes.map(attr => {
        const prefix = `「${attr.label}」の項目は、`;
        return {
          level: attr.type === 'replaced' ? 'alert' : 'danger',
          index: i.index,
          msg : prefix + attr.typeMessageForExport
        }
      });
    });
  }
  public get ingredientErrorMessagesOnImport(): IngredientErrorMessage[] {
    return this.ingredientErrors.flatMap(i => {
      return i.attributes.map(attr => {
        const prefix = `「${attr.label}」の項目は、「${this.targetSystem}」と「スマート食品規格書」のデータの取り扱いが異なるため、`;
        return {
          level: attr.type === 'replaced' ? 'alert' : 'danger',
          index: i.index, // 1始まり
          msg : prefix + attr.typeMessageForImport,
        }
      });
    });
  }
  public get ingredientErrorMessages(): IngredientErrorMessage[] {
    if (this.isImport) {
      return this.ingredientErrorMessagesOnImport;
    } else {
      return this.ingredientErrorMessagesOnExport;
    }
  }
}

export enum SpecImportErrorPropType {
  Name =  'name',
  MANUFACTURE_DATE_FORMAT_TYPE = 'manufacture_date_format_type',
  EXPIRATION_FORMAT_TYPE = 'expiration_format_type',
  EXPIRATION_DAYS_BEFORE_OPEN = 'expiration_days_before_open',
  EXPIRATION_DATE_UNIT_BEFORE_OPEN = 'expiration_date_unit_before_open',
  EXPIRATION_DAYS_AFTER_OPEN = 'expiration_days_after_open',
  ALCOHOL_RATIO = 'alcohol_ratio',
  Seller =  'seller',
  Nutrition = 'nutrition',
  Contamination = 'contamination',
  PACKING_FORM_WEIGHT = 'packing_form_weight',
  PACKING_FORM_TOTAL_WEIGHT = 'packing_form_total_weight',
  PACKING_FORM_WIDTH = 'packing_form_width',
  PACKING_FORM_HEIGHT = 'packing_form_height',
  PACKING_FORM_DEPTH = 'packing_form_depth',
  PACKING_FORM_TOTAL_WEIGHT_UNIT = 'packing_form_total_weight_unit'
}
export const SpecImportErrorPropTypeTabView = {
  [SpecImportErrorPropType.Name]: i18n.t('基本'),
  [SpecImportErrorPropType.MANUFACTURE_DATE_FORMAT_TYPE]: i18n.t('基本'),
  [SpecImportErrorPropType.EXPIRATION_FORMAT_TYPE]: i18n.t('基本'),
  [SpecImportErrorPropType.EXPIRATION_DAYS_BEFORE_OPEN]: i18n.t('基本'),
  [SpecImportErrorPropType.EXPIRATION_DATE_UNIT_BEFORE_OPEN]: i18n.t('基本'),
  [SpecImportErrorPropType.EXPIRATION_DAYS_AFTER_OPEN]: i18n.t('基本'),
  [SpecImportErrorPropType.ALCOHOL_RATIO]: i18n.t('基本'),
  [SpecImportErrorPropType.Seller]: i18n.t('企業'),
  [SpecImportErrorPropType.Nutrition]: i18n.t('栄養'),
  [SpecImportErrorPropType.Contamination]: i18n.t('製造'),
  [SpecImportErrorPropType.PACKING_FORM_WEIGHT]: i18n.t('基本'),
  [SpecImportErrorPropType.PACKING_FORM_TOTAL_WEIGHT]: i18n.t('基本'),
  [SpecImportErrorPropType.PACKING_FORM_WIDTH]: i18n.t('基本'),
  [SpecImportErrorPropType.PACKING_FORM_HEIGHT]: i18n.t('基本'),
  [SpecImportErrorPropType.PACKING_FORM_DEPTH]: i18n.t('基本'),
  [SpecImportErrorPropType.PACKING_FORM_TOTAL_WEIGHT_UNIT]: i18n.t('基本'),
} as { [key in SpecImportErrorPropType]: string };

export type ImExportTypeBasic = 'replaced' | 'required' | 'not_found' | 'invalid';

export class SpecImExportErrorAttribute {
  public prop!: SpecImportErrorPropType;
  public type!: ImExportTypeBasic;
  public source!: string;
  public message!: string;
  constructor(init:SpecImExportErrorAttribute) {
    ObjectUtils.assignLiteralFields(this, init);
  };
  public get tabView(): string {
    return SpecImportErrorPropTypeTabView[this.prop];
  }

  public get errorLevel(): 'alert' | 'danger' {
    return this.type === 'replaced' ? 'alert' : 'danger';
  }
}

export enum SpecImportErrorIngredientPropType {
  Ingredient =  'ingredient',
  Additive =  'additive',
  AdditivePurpose =  'additive_purpose',
  Gmo =  'gmo',
  Origin =  'origin',
  Allergen =  'allergen',
  InvisibleReason =  'invisible_reason',
}

export class SpecImExportErrorIngredientAttribute {
  public parent!: SpecImExportError;

  public prop!: SpecImportErrorIngredientPropType;
  public type!: ImExportTypeBasic | 'as_food';
  public source!: string;
  public target?: string;
  public message?: string;

  constructor(init:SpecImExportErrorIngredientAttribute, parent: SpecImExportError) {
    this.parent = parent;
    ObjectUtils.assignLiteralFields(this, init);
  };

  public get typeMessage(): string {
    return this.parent.isImport ? this.typeMessageForImport : this.typeMessageForExport;
  }
  public get isTypeDanger(): boolean {
    return this.type === 'required' || this.type === 'not_found' || this.type === 'invalid';
  }
  public get typeMessageForImport(): string {
    if (this.message) return this.message;

    if (this.type === 'replaced') {
      return `「${this.source}」から「${this.target}」へと変換されました。ご確認ください。`;
    } else if (this.type === 'as_food') {
      return `「食品扱いとして添加物から原材料」へと変換されました。ご確認ください。`;
    } else {
      return `「${this.source}」を自動で判別することができませんでした。該当の${this.label}を選択してください。`;
    }
  }
  public get typeMessageForExport(): string {
    if (this.message) return this.message;

    if (this.type === 'replaced') {
      return `「${this.source}」から「${this.target}」へと名称変更して出力します。`;
    } else if (this.type === 'required') {
      return `「${this.parent.targetSystem}」で必須ですが、設定されていません。`;
    } else {
      return `「${this.source}」の値は「${this.parent.targetSystem}」に一致する項目が見つからないため、うまく取り込めない可能性があります`;
    }
  }

  public get label() {
    return {
      [SpecImportErrorIngredientPropType.Ingredient]: '原材料',
      [SpecImportErrorIngredientPropType.Additive]: '添加物',
      [SpecImportErrorIngredientPropType.AdditivePurpose]: '添加物用途',
      [SpecImportErrorIngredientPropType.Gmo]: '遺伝子組み換え',
      [SpecImportErrorIngredientPropType.Origin]: '基原原料',
      [SpecImportErrorIngredientPropType.Allergen]: 'アレルゲン',
      [SpecImportErrorIngredientPropType.InvisibleReason]: '非表示の理由',
    }[this.prop] || '';
  }
}
