import {getTextByLang as t} from "@/bootstraps/locale";

export const PackingUnitLang = {
  '個': t({
    ja: "個",
    en: "Piece",
    cn: "个"
  }),
  '本': t({
    ja: "本",
    en: "Sticks",
    cn: "本"
  }),
  '袋': t({
    ja: "袋",
    en: "Bags",
    cn: "袋"
  }),
  '箱': t({
    ja: "箱",
    en: "Boxes",
    cn: "箱"
  }),
  '束': t({
    ja: "束",
    en: "Bundles",
    cn: "束"
  }),
  '缶': t({
    ja: "缶",
    en: "Cans",
    cn: "罐"
  }),
  '枚': t({
    ja: "枚",
    en: "Sheets",
    cn: "枚"
  }),
  'ヶ': t({
    ja: "ヶ",
    en: "Piece",
    cn: ""
  }),
  'タル': t({
    ja: "タル",
    en: "Barrel",
    cn: "塔"
  }),
  'C/S': t({
    ja: "C/S",
    en: "C/S",
    cn: "C/S"
  }),
  'PC': t({
    ja: "PC",
    en: "PC",
    cn: "PC"
  }),
  'mg': t({
    ja: "mg",
    en: "mg",
    cn: "mg"
  }),
  'g': t({
    ja: "g",
    en: "g",
    cn: "g"
  }),
  'ml': t({
    ja: "ml",
    en: "ml",
    cn: "ml"
  }),
  'l': t({
    ja: "l",
    en: "l",
    cn: "l"
  }),
  'kl': t({
    ja: "kl",
    en: "kl",
    cn: "kl"
  }),
  'ガロン': t({
    ja: "ガロン",
    en: "Gallon",
    cn: "加仑"
  }),
  'カートン': t({
    ja: "カートン",
    en: "Carton",
    cn: "条"
  }),
  'セット': t({
    ja: "セット",
    en: "Set",
    cn: "套"
  }),
  '切': t({
    ja: "切",
    en: "Piece",
    cn: "盘"
  }),
  '足': t({
    ja: "足",
    en: "Pair ",
    cn: "只"
  }),
  '膳': t({
    ja: "膳",
    en: "Bowl",
    cn: "顿"
  }),
  '巻': t({
    ja: "巻",
    en: "Roll",
    cn: "卷"
  }),
  '双': t({
    ja: "双",
    en: "Pair",
    cn: "双"
  }),
  '冊': t({
    ja: "冊",
    en: "Book",
    cn: "册"
  }),
  '台': t({
    ja: "台",
    en: "Table",
    cn: "台"
  }),
  'ダース': t({
    ja: "ダース",
    en: "Dozen",
    cn: "打"
  }),
  '着': t({
    ja: "着",
    en: "Cloth",
    cn: "件"
  }),
  '人': t({
    ja: "人",
    en: "People",
    cn: "人"
  }),
  '尾': t({
    ja: "尾",
    en: "Fish",
    cn: "匹"
  }),
  '甲': t({
    ja: "甲",
    en: "Kouri(10 cases)",
    cn: "甲"
  }),
  'ボール': t({
    ja: "ボール",
    en: "Balls",
    cn: "球"
  }),
  '平米': t({
    ja: "平米",
    en: "Square meter",
    cn: "平米"
  }),
  '杯': t({
    ja: "杯",
    en: "Cup",
    cn: "杯"
  }),
  'ポンド': t({
    ja: "ポンド",
    en: "Pounds",
    cn: "磅"
  }),
  '瓶': t({
    ja: "瓶",
    en: "Bottle",
    cn: "瓶"
  }),
  '丁': t({
    ja: "丁",
    en: "Tofu",
    cn: "第四"
  }),
  '羽': t({
    ja: "羽",
    en: "Bird",
    cn: "只"
  }),
  '斤': t({
    ja: "斤",
    en: "Loaf",
    cn: "斤"
  }),
  '客': t({
    ja: "客",
    en: "Cup/Bowl ",
    cn: "客"
  }),
  'メ': t({
    ja: "メ",
    en: "",
    cn: "目"
  }),
  '貫目': t({
    ja: "貫目",
    en: "3.75㎏",
    cn: "貫"
  }),
  '玉': t({
    ja: "玉",
    en: "Ball",
    cn: "只"
  }),
  '株': t({
    ja: "株",
    en: "Head (vegetable)",
    cn: "株"
  }),
  'ネット': t({
    ja: "ネット",
    en: "Net",
    cn: "网"
  }),
  '把': t({
    ja: "把",
    en: "Bundle",
    cn: "把"
  }),
  '組': t({
    ja: "組",
    en: "Set",
    cn: "组"
  }),
  '片': t({
    ja: "片",
    en: "Piece",
    cn: "片"
  }),
  'ロット': t({
    ja: "ロット",
    en: "Lot",
    cn: "批量"
  }),
  '卓': t({
    ja: "卓",
    en: "Table",
    cn: "卓"
  }),
  '対': t({
    ja: "対",
    en: "Pair",
    cn: "对"
  }),
  '房': t({
    ja: "房",
    en: "Bunch",
    cn: "房"
  }),
  '匹': t({
    ja: "匹",
    en: "",
    cn: "匹"
  }),
  '頭': t({
    ja: "頭",
    en: "",
    cn: "头"
  }),
  '人前': t({
    ja: "人前",
    en: "Serving",
    cn: "人份"
  }),
  'ピース': t({
    ja: "ピース",
    en: "Piece",
    cn: "片"
  }),
  'ドラム': t({
    ja: "ドラム",
    en: "Drum",
    cn: "桶"
  }),
  'カゴ': t({
    ja: "カゴ",
    en: "Bucket",
    cn: "笼"
  }),
  '丈': t({
    ja: "丈",
    en: "",
    cn: "丈"
  }),
  '包': t({
    ja: "包",
    en: "Package",
    cn: "包"
  }),
  '腹': t({
    ja: "腹",
    en: "Mentaiko",
    cn: "肚"
  }),
  '体': t({
    ja: "体",
    en: "Body",
    cn: "身"
  }),
  'コロ': t({
    ja: "コロ",
    en: "",
    cn: "轴"
  }),
  '合': t({
    ja: "合",
    en: "Gou",
    cn: "合"
  }),
  '升': t({
    ja: "升",
    en: "Sho",
    cn: "升"
  }),
  'トレー': t({
    ja: "トレー",
    en: "Tray",
    cn: "盘"
  }),
  '食': t({
    ja: "食",
    en: "Meal",
    cn: "餐"
  }),
  '打': t({
    ja: "打",
    en: "Dozen",
    cn: "打"
  }),
  '反': t({
    ja: "反",
    en: "Roll",
    cn: "反"
  }),
  '件': t({
    ja: "件",
    en: "",
    cn: "件"
  }),
  '式': t({
    ja: "式",
    en: "Set ",
    cn: "式"
  }),
  '帖': t({
    ja: "帖",
    en: "Tatami mat ",
    cn: "贴"
  }),
  '斗': t({
    ja: "斗",
    en: "To (18.039L)",
    cn: "斗"
  }),
  'ブロック': t({
    ja: "ブロック",
    en: "Block",
    cn: "块"
  }),
  '連': t({
    ja: "連",
    en: "Group",
    cn: "连"
  }),
  'm': t({
    ja: "m",
    en: "m",
    cn: "个榻榻米"
  }),
  '畳': t({
    ja: "畳",
    en: "Tatami mat",
    cn: "串"
  }),
  '串': t({
    ja: "串",
    en: "Skewer",
    cn: "帐"
  }),
  '帳': t({
    ja: "帳",
    en: "Book ",
    cn: "折"
  }),
  '折': t({
    ja: "折",
    en: "Fold",
    cn: "回"
  }),
  '回': t({
    ja: "回",
    en: "Time",
    cn: "色"
  }),
  '色': t({
    ja: "色",
    en: "Color",
    cn: "栋"
  }),
  '軒': t({
    ja: "軒",
    en: "House",
    cn: "节"
  }),
  '節': t({
    ja: "節",
    en: "",
    cn: "壶"
  }),
  '壺': t({
    ja: "壺",
    en: "Pot",
    cn: "基"
  }),
  '基': t({
    ja: "基",
    en: "Base",
    cn: "钵"
  }),
  '括': t({
    ja: "括",
    en: "Bundle",
    cn: "括"
  }),
  '桶': t({
    ja: "桶",
    en: "Tub",
    cn: "桶"
  }),
  '函': t({
    ja: "函",
    en: "",
    cn: "封"
  }),
  'ボトル': t({
    ja: "ボトル",
    en: "Bottles",
    cn: "瓶"
  }),
  '室': t({
    ja: "室",
    en: "Room",
    cn: "间"
  }),
  '部': t({
    ja: "部",
    en: "Part",
    cn: "部"
  }),
  'ホール': t({
    ja: "ホール",
    en: "Hall",
    cn: "堂"
  }),
  '立米': t({
    ja: "立米",
    en: "㎥",
    cn: "立方米"
  }),
  'ハンク': t({
    ja: "ハンク",
    en: "",
    cn: "汉克"
  }),
  '肩': t({
    ja: "肩",
    en: "Sholder",
    cn: "肩"
  }),
  '中箱': t({
    ja: "中箱",
    en: "",
    cn: "中箱"
  }),
  'cc': t({
    ja: "cc",
    en: "cc",
    cn: "cc"
  }),
  '口': t({
    ja: "口",
    en: "",
    cn: "口"
  }),
  '釜': t({
    ja: "釜",
    en: "Kama",
    cn: "斧"
  }),
  'パット': t({
    ja: "パット",
    en: "Pat",
    cn: "衬肩"
  }),
  'kw': t({
    ja: "kw",
    en: "kw",
    cn: "kw"
  }),
  '点': t({
    ja: "点",
    en: "Point",
    cn: "点"
  }),
  '検体': t({
    ja: "検体",
    en: "Specimen",
    cn: "检体"
  }),
  '俵': t({
    ja: "俵",
    en: "Tawara",
    cn: "草包"
  }),
  'カメ': t({
    ja: "カメ",
    en: "Kame",
    cn: "龟"
  }),
  '年': t({
    ja: "年",
    en: "Year",
    cn: "年"
  }),
  '月': t({
    ja: "月",
    en: "Month",
    cn: "月"
  }),
  '週': t({
    ja: "週",
    en: "Week",
    cn: "周"
  }),
  '日': t({
    ja: "日",
    en: "Day",
    cn: "天"
  }),
  '時間': t({
    ja: "時間",
    en: "Time",
    cn: "时间"
  }),
  'シート': t({
    ja: "シート",
    en: "Sheet",
    cn: "座"
  }),
  '枠': t({
    ja: "枠",
    en: "Frame",
    cn: "框"
  }),
  '皿': t({
    ja: "皿",
    en: "Plate",
    cn: "盘"
  }),
};
