


























import {Component, Prop, Vue} from "vue-property-decorator";

import {AllergenList} from "@/entities/allergy-entity";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) private value!:number[]|number;
  @Prop({default: false}) private disabled!:boolean;
  @Prop({default: false}) private multiple!:boolean;
  @Prop({required: false}) private multipleLimit?:number;
  @Prop({required: false}) private size?:string;

  private readonly AllergenList = AllergenList;

  private get c_value() { return this.value; };
  private set c_value(val) { this.$emit('input', val); };
}
