













import { Vue, Component, Prop } from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import {AuthUser} from "@/libs/auth";
import {CompanyDepartmentService, IResourceBelongingToDepartment} from "@/services/company-department-service";

function isEditableMember(user: AuthUser) {
  return user.isCompanyEditable;
}
function isEditableDepartmentResource(user: AuthUser, company: CompanyEntity, resource: IResourceBelongingToDepartment) {
  return (new CompanyDepartmentService(user, company)).canUpdate(resource);
}
export function isEditable(user: AuthUser, company: CompanyEntity, resource: IResourceBelongingToDepartment) {
  return user.isCompanyEditable && (new CompanyDepartmentService(user, company!)).canUpdate(resource);
}
@Component({

})
export default class EditableSlot extends Vue {
  @Prop({required: true}) private user!: AuthUser;
  @Prop({required: true}) private company!: CompanyEntity;
  @Prop({required: true}) private resource!: IResourceBelongingToDepartment;

  public isEditable() {
    return isEditable(this.user, this.company, this.resource);
  }
  private get unEditableMessage() {
    if (!isEditableMember(this.user)) {
      return this.$t('閲覧メンバーはこの機能は使用できません');
    }
    if (!isEditableDepartmentResource(this.user, this.company, this.resource)) {
      return this.$t('所属外の部署のデータは編集できません');
    }
    return '';
  }
};
