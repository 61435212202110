import * as Ja from './ja/setting';
import * as En from './en/setting';
import * as Cn from './cn/setting';
import {getTextByLang} from "@/bootstraps/locale";
import {
  approvalFlowStepCompletionCondition,
  hiddenWhenAllSameNameAdditiveInvisible,
  hiddenWhenAllSameNameIngredientInvisible,
  isMfaEmailEnabled,
  reconfirmationReminder,
  specRequestDefaultRequiredFields
} from "./ja/setting";

export const help = {
  intraCategory: getTextByLang({
    ja: Ja.intraCategory,
    // TODO: LANG
  }),
  hiddenWhenAllSameNameIngredientInvisible: getTextByLang({
    ja: Ja.hiddenWhenAllSameNameIngredientInvisible,
    // TODO: LANG
  }),
  hiddenWhenAllSameNameAdditiveInvisible: getTextByLang({
    ja: Ja.hiddenWhenAllSameNameAdditiveInvisible,
    // TODO: LANG
  }),
  gmo2023: getTextByLang({
    ja: Ja.gmo2023,
    // TODO: LANG
  }),
  accessibilityToOtherDeptResources: getTextByLang({
    ja: Ja.accessibilityToOtherDeptResources,
    en: En.accessibilityToOtherDeptResources,
    cn: Cn.accessibilityToOtherDeptResources,
  }),
  memberDepartments: getTextByLang({
    ja: Ja.memberDepartments,
    en: En.memberDepartments,
    cn: Cn.memberDepartments,
  }),
  resourceDepartments: getTextByLang({
    ja: Ja.resourceDepartments,
    en: En.resourceDepartments,
    cn: Cn.resourceDepartments,
  }),
  reconfirmationNotification: getTextByLang({
    ja: Ja.reconfirmationNotification,
    en: En.reconfirmationNotification,
    cn: Cn.reconfirmationNotification,
  }),
  reconfirmationReminder: getTextByLang({
    ja: Ja.reconfirmationReminder,
    // TODO: LANG
  }),
  specRequestDefaultRequiredFields: getTextByLang({
    ja: Ja.specRequestDefaultRequiredFields,
    // TODO: LANG
  }),
  approvalFlowForAcceptanceEnabled: getTextByLang({
    ja: Ja.approvalFlowForAcceptanceEnabled,
  }),
  approvalFlowForAcceptanceIsEditableOnProgress: getTextByLang({
    ja: Ja.approvalFlowForAcceptanceIsEditableOnProgress,
  }),
  approvalFlowForOwnedSpecEnabled: getTextByLang({
    ja: Ja.approvalFlowForOwnedSpecEnabled,
  }),
  approvalFlowForOwnedSpecIsEditableOnProgress: getTextByLang({
    ja: Ja.approvalFlowForOwnedSpecIsEditableOnProgress,
  }),
  approvalFlowDepartments: getTextByLang({
    ja: Ja.approvalFlowDepartments,
  }),
  approvalFlowStepCompletionCondition: getTextByLang({
    ja: Ja.approvalFlowStepCompletionCondition,
  }),
  passwordMinCharLength: getTextByLang({
    ja: Ja.passwordMinCharLength,
  }),
  isMfaEmailEnabled: getTextByLang({
    ja: Ja.isMfaEmailEnabled,
  }),
  accessibleIpAddresses: getTextByLang({
    ja: Ja.accessibleIpAddresses,
  }),
}

