






























import { Component, Vue, Prop } from 'vue-property-decorator';
import IngredientEntity from "@/entities/ingredient-entity";
import ProductEntity from "@/entities/product-entity";
import OnFileSelectedHandler from "@/components/Form/OnFileSelectedHandler";
import AttachmentEntity from "@/entities/attachment-entity";
import IngredientRepository from "@/repositories/company/ingredient-repository";
import ProductRepository from "@/repositories/company/product-repository";

@Component
export default class extends Vue {
  @Prop({required: true}) private model!:IngredientEntity|ProductEntity;
  @Prop({required: true}) private repository!:IngredientRepository|ProductRepository;

  private attachmentError:string = '';
  private loadingAttachmentList = false;

  private get isCreating() {
    return !this.model.id;
  }

  private get type() {
    if (this.model instanceof IngredientEntity) {
      return 'ingredients';
    } else if (this.model instanceof ProductEntity) {
      return 'products';
    }
    throw new Error('invalid operation FooterSection@type');
  }

  private mounted() {
    this.loadAttachmentList().then(() => {
      this.$emit('initialized');
    });
  }

  private async loadAttachmentList() {
    if(!this.model.id) return

    this.loadingAttachmentList = true;
    try {
      const res = await this.repository.getAttachments(this.model.id);
      this.model.attachments.push(...res);
    } catch(err) {
      this.$message({type: 'error', message: err.message});
      throw err;
    } finally {
      this.loadingAttachmentList = false;
    }
  }

  private async loadAttachment(attachment:AttachmentEntity) {
    if (!!attachment.body) return;

    new Promise((resolve, reject) => {
      attachment.isLoading = true;

      return fetch(attachment.url!).then(r => {
        r.blob().then(blob => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          }
          reader.readAsDataURL(blob);
        });
      });
    }).then((res:any) => {
      attachment.body = res;
    }).catch(err => {
      this.$message({type: 'error', message: err.message});
      throw err;
    }).finally(() => {
      attachment.isLoading = false;
    });
  }


  private onFileSelected(evt:any) {
    if (!evt.target.files.length || !evt.target.files[0]) {
      this.attachmentError = 'ファイルがうまく読み取れませんでした';
      return;
    }
    const filename = evt.target.files[0].name;
    if (this.model.attachments.some(a => a.filename === filename)) {
      return;
    }

    const handler = new OnFileSelectedHandler({
      maxFileSize: 20 * 1024 * 1024,
      availableFileTypeMimeList: AttachmentEntity.ACCEPT_MIME_LIST
    });
    handler.onFileSelected(evt.target.files)
      .then((res:any) => {
        this.model.attachments.push(new AttachmentEntity({
          filename: filename,
          body: res.target.result,
        }));

        const obj = document.getElementById("js-file") as any;
        obj.value = "";

        this.attachmentError = '';
      })
      .catch((err) => {
        this.attachmentError = err;
      });
  }

  private removeAttachment(attachment:AttachmentEntity) {
    if (attachment.isAdded) {
      this.model.attachments.splice(this.model.attachments.indexOf(attachment), 1);
    } else {
      attachment.__destroy = true;
    }
  }
}
