import RepositoryBase from "@/repositories/repository-base";
import ObjectUtils from "@/utils/object-utils";

export default class IngredientTemplateRepository extends RepositoryBase<IngredientTemplate> {
  protected endpoint = "ingredient-templates";
  protected ctor:new(data) => IngredientTemplate = IngredientTemplate;

  public async search(query, params = {}): Promise<IngredientTemplate[]>  {
    const p = Object.assign({q:query}, params);
    const res = await this.get(this.endpoint + "/search", p);
    return this.parseResponse(res) as IngredientTemplate[];
  }
}

export class IngredientTemplate {
  public id!:number;
  public name!:string;
  constructor(init:IngredientTemplate) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

