export const accessibilityToOtherDeptResources =
  `If you have enabled department management, you can set the permissions for viewing and editing data between departments.

If "Mng." is set for the department to which a member belongs, regardless of this setting, the member can view and edit all data if he/she is an "Editing Member", and can view all data if he/she is a "Viewing Member".

- View and Edit all data.

Regardless of the department you belong to, you can view all data, and if you are an editing member, you can edit the data.

- View all data and Edit belonged department's data

All data can be viewed regardless of the department to which you belong. However, if you are not a member of a department, you cannot edit the data, even if you are an "Editing member".

- View and Edit belonged department's data, and Quote other department's data.

Hides data outside of the department to which you belong. However, it is possible to view or quote from the direct URL. You cannot edit the data even if you are an "Editing member".

- View and Edit only belonged department's data.

Data outside of belonged department cannot be viewed, edited, or quoted. You cannot quote the data. Even if you are an "Editing Member", you cannot edit the data.`.replace(/\n/g, '<br>');

export const memberDepartments =
  `You can set the department to which this member belongs.

By setting the department to which the member belongs, such as the member, specifications, food labels, etc., you can restrict other departments to view.

If the department function is ON, at least one department must be set.

Note: About "Mng." Tag

Members belonging to "Mng." can view all specifications and products regardless of other departments.

In addition, the "Mng." department is compulsorily assigned to Administration members and cannot be removed.`.replace(/\n/g, '<br>');

export const resourceDepartments =
  `Select the department that manages this data.

If not specified, only the managing member can view it.

To check and set which department a member belongs to, the managing member should go to "Member Name" > "Organization Account Settings" > "Add/Manage Members" in the upper right corner of the screen.`.replace(/\n/g, '<br>');

export const reconfirmationNotification =
`When a certain period has passed after the spec has been updated, a notification is sent so you can confirm the latest version of the spec.

The date used as the basis can be selected from the following

- Last Update Date  (the last published date of the receipt spec through the Smart Food Spec, or the date of the last modification of the document excluding management information in case of manual input).
- Application Date (the date the content of the spec get effective)`.replace(/\n/g, '<br>');
