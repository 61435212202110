import formatDate from 'date-fns/format';

import parseCsv from 'csv-parse/lib/sync';
import Encoding from 'encoding-japanese';
import isNumber from "lodash/isNumber";
import isSafeInteger from "lodash/isSafeInteger";
import IFile from "@/contracts/i-file";

export enum ColumnType {
  String = 'string',
  Int = 'int',
  IntUnsigned = 'int_unsigned',
  Decimal = 'decimal',
  DecimalUnsigned = 'decimal_unsigned',
  Date = 'date',
}
export type ColumnSetting = { prop?: string; header: string, type: ColumnType, required?: boolean; nullable?: boolean; };

export function isColumnTypeNumber(type: ColumnType) {
  return [ColumnType.Int, ColumnType.IntUnsigned, ColumnType.Decimal, ColumnType.DecimalUnsigned].includes(type);
}
export function castIfColumnTypeNumber(type: ColumnType, val:any) {
  return isColumnTypeNumber(type) ? Number(val) : val;
}

export class ImportCsvUtil {
  public constructor(public readonly columnSettings: ColumnSetting[]) {
  }

  public parseTextToColumns(body: ArrayBuffer): any[] | null {
    const text = this.modifyEncoding(body);
    const dataList = this.parseCsv(text);
    if (!dataList) return null;

    return dataList;
  }

  protected modifyEncoding(buffer:ArrayBuffer) {
    const codes = new Uint8Array(buffer);
    return Encoding.convert(codes, {to: 'UNICODE', from: 'AUTO', type: 'string'});
  }

  protected parseCsv(text): [] | null {
    try {
      const options = {
        columns: true,
        from_line: 1,
        cast: true,
        cast_date: false,
        bom: true,
        trim: true,
        skipEmptyLines: true,
      };
      return parseCsv(text, options);
    } catch (error) {
      const errMsg = this.getCsvParseError(error.message) + `(${error.message})`;
      throw new Error(errMsg);
    }
  }

  protected getCsvParseError(err): string {
    if (err.startsWith("Invalid Closing Quote")) {
      return "クォーテーション(\")が閉じられていない可能性があります";
    } else if (err.startsWith("Invalid opening quote")) {
      return "クォーテーションを確認ください";
    } else if (err.startsWith("Max Record Size")) {
      return "ファイルが大きすぎます。分割して再度お試しください";
    } else if (err.startsWith("Invalid Record Length: header length")) {
      return "ヘッダーとCSVデータの数が一致しません";
    } else if (err.startsWith("Invalid Record Length")) {
      return "長さが不正な行があります";
    } else {
      return "";
    }
  }

  public easyValidateCsv(dataList: any[]) {
    const errors:string[] = [];

    this.columnSettings.forEach(colSetting => {
      // check required
      if (dataList.some(row => !(colSetting.header in row))) {
        if (colSetting.required === true) {
          errors.push(`項目名「${colSetting.header}」が見つかりません。この項目は必須です`);
        } else {
          return;
        }
      }

      dataList.forEach((row, index) => {
        // check nullable
        const val = row[colSetting.header];
        if(val === null || val === '') {
          if (colSetting.nullable === true) {
            return;
          } else {
            errors.push(`${index+1}行目の${colSetting.header}の値が空です。この項目は空にできません。`);
          }
        }

        // check type
        const errorPrefix = `${index+1}行目の「${colSetting.header}」`;
        switch (colSetting.type) {
          case ColumnType.Int:
            if (!isSafeInteger(val)) {
              errors.push(`${errorPrefix}が整数形式になっていません`);
            }
            break;
          case ColumnType.IntUnsigned:
            if (!isSafeInteger(val) || val < 0) {
              errors.push(`${errorPrefix}が正の整数形式になっていません`);
            }
            break;
          case ColumnType.Decimal:
            if (!isNumber(val)) {
              errors.push(`${errorPrefix}が数値形式になっていません`);
            }
            break;
          case ColumnType.DecimalUnsigned:
            if (!isNumber(val) || val < 0) {
              errors.push(`${errorPrefix}が正の数値形式になっていません`);
            }
            break;
          case ColumnType.Date:
            if (!Date.parse(val)) {
              errors.push(`${errorPrefix}が日付形式になっていません`);
            }
            break;
        }
      });
    });

    return errors;
  }

  public formatDataForPreview(val:any, setting: ColumnSetting): string {
    if (val === '' || val === null || val === undefined) return '';

    switch (setting.type) {
      case ColumnType.Int:
      case ColumnType.IntUnsigned:
      case ColumnType.Decimal:
      case ColumnType.DecimalUnsigned:
        return val.toLocaleString();
      case ColumnType.Date:
        return formatDate(val, 'YYYY/MM/DD HH:mm');
    }

    return val;
  }
}
