import {Component, Prop, Watch} from "vue-property-decorator";
import AuthViewBase from "@/views/AuthViewBase";
import {RawLocation} from "vue-router/types/router";
import SpecEntity from "@/entities/specs/spec-entity";

Component.registerHooks([
  'beforeRouteLeave'
]);

@Component
export default class CreatePageBase extends AuthViewBase {
  // Handle Cancel
  public isAnyChanged = false;
  public isForwardingProgrammatically = false;

  protected get isCreateMode(): boolean {
    return true;
  }

  private originalBeforeUnload:any = null;

  private beforeCreate() {
    this.originalBeforeUnload = window.onbeforeunload;
    window.onbeforeunload = (e:BeforeUnloadEvent) => {
      if (!this.isAnyChanged) return;
      const txt = this.$t('未保存の変更があります。このまま進むと内容は保存されませんが、よろしいですか？');
      e.preventDefault();
      e.returnValue = txt;
      return txt;
    }
  }
  private beforeDestroy() {
    window.onbeforeunload = this.originalBeforeUnload;
    this.onBeforeDestroy();
  }

  private confirmLeave() {
    const title = this.$t(this.isCreateMode ? '作成のキャンセル' : '変更のキャンセル');
    const message = this.$t(this.isCreateMode ? '作成中の内容は保存されません。' : '前回から変更した内容は保存されません。');

    // 呼び出し元でpromise処理するため、ここではcatchしない
    return this.$confirm(message + this.$t(`本当にキャンセルしますか？`), title, {
      confirmButtonText: this.$t('はい'),
      cancelButtonText: this.$t('いいえ'),
      confirmButtonClass: 'c-button primary no-focus',
      cancelButtonClass: 'c-button primary-inverse no-focus'
    });
  }

  protected cancel(to:string) {
    if (!this.isAnyChanged) {
      return this.$router.push({name: to});
    }

    this.confirmLeave().then(() => {
      this.isForwardingProgrammatically = true;
      this.$router.push({name: to});
    }).catch(err => { if(err !== 'cancel') throw err; });
  }

  protected forward(to:RawLocation) {
    this.isForwardingProgrammatically = true;
    return this.$router.push(to).then(() => {
      this.isForwardingProgrammatically = false;
    });
  }

  protected beforeRouteLeave (to, from , next) {
    if (this.isForwardingProgrammatically) return next();

    if (this.isAnyChanged) {
      this.confirmLeave().then(() => next()).catch(() => next(false));
    } else {
      next();
    }
  }


  protected undo() {}
  protected modelHistory:SpecEntity[] = [];
  private handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
      if (this.modelHistory.length === 0) return;
      this.undo();
    }
  }
  private mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.onMounted();
  }
  protected onMounted() { }
  protected onBeforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

}
