








import {Component, Prop, Vue} from "vue-property-decorator";

import {AllergenList} from "@/entities/allergy-entity";
import FilterableMultipleSelect from "@/components/FilterableSelect/FilterableMultipleSelect.vue";

@Component({
  components: {
    FilterableMultipleSelect,
  }
})
export default class extends Vue {
  @Prop({required: true}) private value!:number[]; // allergenIds
  @Prop({default: false}) private disabled!:boolean;

  private readonly AllergenList = AllergenList;

  public get allergenNames(): string[] {
    return this.AllergenList
      .filter(a => this.value.includes(a.id))
      .map(a => a.name);
  }
  public set allergenNames(names: string[]) {
    this.$emit('input', AllergenList.filter(a => names.includes(a.name)).map(a => a.id));
  }
}
