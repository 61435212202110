







import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({default: 21}) private faqId!:number;

  private get mayHasFilteredResults() {
    return this.company.setting.needFilterOtherDepartmentResourcesOnIndex;
  }

}
