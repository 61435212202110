







import { Component, Vue, Prop } from 'vue-property-decorator';
import IngredientEntity from "@/entities/ingredient-entity";
import ProductEntity from "@/entities/product-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private model!:IngredientEntity|ProductEntity;
}
