import { PackingUnitLang as lang } from "@/lang/enum/packing-unit-lang";

export const Units = [
  lang.本,
  lang.袋,
  lang.箱,
  lang.束,
  lang.缶,
  lang.枚,
  lang.ヶ,
  lang.タル,
  lang['C/S'],
  lang.PC,
  lang.mg,
  lang.g,
  lang.ml,
  lang.l,
  lang.kl,
  lang.ガロン,
  lang.カートン,
  lang.セット,
  lang.切,
  lang.足,
  lang.膳,
  lang.巻,
  lang.双,
  lang.冊,
  lang.台,
  lang.ダース,
  lang.着,
  lang.人,
  lang.尾,
  lang.甲,
  lang.ボール,
  lang.平米,
  lang.杯,
  lang.ポンド,
  lang.瓶,
  lang.丁,
  lang.羽,
  lang.斤,
  lang.客,
  lang.メ,
  lang.貫目,
  lang.玉,
  lang.株,
  lang.ネット,
  lang.把,
  lang.組,
  lang.片,
  lang.ロット,
  lang.卓,
  lang.対,
  lang.房,
  lang.匹,
  lang.頭,
  lang.人前,
  lang.ピース,
  lang.ドラム,
  lang.カゴ,
  lang.丈,
  lang.包,
  lang.腹,
  lang.体,
  lang.コロ,
  lang.合,
  lang.升,
  lang.トレー,
  lang.食,
  lang.打,
  lang.反,
  lang.件,
  lang.式,
  lang.帖,
  lang.斗,
  lang.ブロック,
  lang.連,
  lang.m,
  lang.畳,
  lang.串,
  lang.帳,
  lang.折,
  lang.回,
  lang.色,
  lang.軒,
  lang.節,
  lang.壺,
  lang.基,
  lang.括,
  lang.桶,
  lang.函,
  lang.ボトル,
  lang.室,
  lang.部,
  lang.ホール,
  lang.立米,
  lang.ハンク,
  lang.肩,
  lang.中箱,
  lang.cc,
  lang.口,
  lang.釜,
  lang.パット,
  lang.kw,
  lang.点,
  lang.検体,
  lang.俵,
  lang.カメ,
  lang.年,
  lang.月,
  lang.週,
  lang.日,
  lang.時間,
  lang.シート,
  lang.枠,
  lang.皿,

];
