import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import * as Sentry from "@sentry/browser";

export class AccessLogRepository extends RepositoryBase<any> {
  protected companyId!:number;

  public constructor(companyId: number) {
    super();
    this.companyId = companyId;
    this.endpoint = `access/companies/${companyId}/`;
  }

  public async logProduct(productId: number) {
    try {
      await this.post(this.endpoint + `products/${productId}`);
      return true;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }
  public async logIngredient(ingredientId: number) {
    try {
      await this.post(this.endpoint + `ingredients/${ingredientId}`);
      return true;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }
  public async logSpec(specId: number) {
    try {
      await this.post(this.endpoint + `specs/${specId}`);
      return true;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }
  public async logAcceptanceSpec(specAcceptanceSpecId: number) {
    try {
      await this.post(this.endpoint + `acceptance-specs/${specAcceptanceSpecId}`);
      return true;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }
}
