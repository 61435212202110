









































































import { Component, Prop } from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";

import IngredientRepository from '@/repositories/company/ingredient-repository';
import ProductRepository from "@/repositories/company/product-repository";

import LoadingHandler from "@/utils/loading-handler";
import NotificationEntity from "@/entities/notification-entity";
import {NotificationRepository} from "@/repositories/company/notification-repository";

@Component({
  components: {
  }
})
export default class extends AuthViewBase {
  private ingredientCount:number|null = null;
  private productCount:number|null = null;

  private notifications:NotificationEntity[]|null = null;

  private async created() {
    await LoadingHandler(() => {
      return Promise.all([
        new IngredientRepository(this.companyId).count(),
        new ProductRepository(this.companyId).count(),
        new NotificationRepository(this.companyId).findNotificationsForCurrentApp()
      ]).then(res => {
        this.ingredientCount = res[0];
        this.productCount = res[1];
        this.notifications = res[2];
      });
    });
  }
}
