







import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import {help as PopoverTextSetting} from '@/lang/help/setting';
import ResourceDepartmentSelect from "@/views/components/Department/ResourceDepartmentSelect.vue";
import {i18n} from "@/bootstraps/locale";

@Component({
  components: {
    ResourceDepartmentSelect,
  }
})
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private value!: number[];
  @Prop({default: i18n.t('自社の担当部署')}) private label!: string;

  private readonly PopoverTextSetting = PopoverTextSetting;

  private get model() {
    return this.value;
  }
  private set model(val) {
    this.$emit('input', val);
  }

}
