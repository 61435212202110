





















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import * as PopoverText from '@/lang/help/ingredient-create';

import NutritionEntity, {DetailList, MineralList, VitaminList} from "@/entities/nutrition-entity";
import IngredientNutritionEntity from "@/entities/ingredient-nutrition-entity";

import NutritionInput from './NutritionInput.vue';
import NutritionBasicSection from './NutritionBasicSection.vue';
import NutritionRepository, {TNutritionListItem} from "@/repositories/master/nutrition-repository";

@Component({
  components: {
    NutritionInput,
    NutritionBasicSection,
  }
})
export default class extends Vue {
  @Prop({required: true}) nutrition!:IngredientNutritionEntity;
  @Prop({required: true}) switchNatriumAndSalt!:boolean;
  @Prop({default: false}) disabled!:boolean;

  nutritionList:TNutritionListItem[] = [];
  selectedNutrition:NutritionEntity|null = null;

  PopoverText = PopoverText;

  nutritionLoading:boolean = true;

  readonly fatList1 = DetailList.filter(n => ['lipid', 'saturatedFattyAcids', 'monoUnsaturatedFattyAcids', 'polyUnsaturatedFattyAcids', 'omega3', 'omega6',].includes(n.prop));
  readonly fatList2 = DetailList.filter(n => ['transFattyAcids', 'cholesterol', ].includes(n.prop));
  readonly carbList = DetailList.filter(n => ['carb', 'sugar', 'saccharides', 'fiber', 'solubleFiber', 'insolubleFiber',].includes(n.prop));
  readonly vitaminAList = VitaminList.filter(n => ['vitaminA', 'retinol', 'alphaCarotene', 'betaCarotene', 'betaCryptoxanthin', 'betaCaroteneEquivalent',].includes(n.prop!));
  readonly vitaminBList1 = VitaminList.filter(n => ['vitaminB1', 'vitaminB2', 'vitaminB6', 'vitaminB12' ].includes(n.prop!));
  readonly vitaminBList2 = VitaminList.filter(n => ['niacin', 'folicAcid', 'pantothenicAcid', 'biotin' ].includes(n.prop!));
  readonly vitaminOtherList = VitaminList.filter(n => ['vitaminC', 'vitaminD', 'vitaminK' ].includes(n.prop!));
  readonly mineralList1 = MineralList.filter(n => ['zinc', 'potassium', 'calcium', 'chromium', 'selenium', 'iron' ].includes(n.prop));
  readonly mineralList2 = MineralList.filter(n => ['copper', 'magnesium', 'manganese', 'molybdenum', 'iodine', 'phosphorous' ].includes(n.prop));

  private created() {
    const repo = new NutritionRepository();
    const promises:any = [];
    promises.push(repo.list().then(list => {
      this.nutritionList = list;
    }));

    if (this.nutrition.referredNutritionId) {
      promises.push(repo.findById(this.nutrition.referredNutritionId).then(data => {
        this.selectedNutrition = data;
      }));
    }

    Promise.all(promises).then(() => {
      // 一覧に存在しない(deprecated)マスタを参照している場合
      if(this.selectedNutrition && !this.nutritionList.find(n => n.id === this.selectedNutrition!.id)) {
        this.nutritionList.push({
          id: this.selectedNutrition!.id,
          name: '【旧】' + this.selectedNutrition!.name,
          synonym: this.selectedNutrition!.name + " " + this.selectedNutrition!.synonym,
        });
      }

      this.nutritionLoading = false;
    });
  }

  public async setSelectedNutrition(nutritionId:number|null) {
    if (nutritionId) {
      this.selectedNutrition = await (new NutritionRepository).findById(nutritionId);
    } else {
      this.selectedNutrition = null;
    }
  }
  public async onReferenceNutritionSelected(nutritionId:number|null) {
    if (nutritionId) {
      this.nutritionLoading = true;
      await this.setSelectedNutrition(nutritionId);
      this.nutrition.setValueFromNutrition(this.selectedNutrition!);
      this.nutritionLoading = false;
    } else {
      this.selectedNutrition = null;
    }
  }

  get isNutritionChangedAfterReferred() {
    if (!this.selectedNutrition) return true;

    return !this.nutrition.equalToNutrition(this.selectedNutrition!);
  }
}
