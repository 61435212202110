import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import NutritionEntity from "@/entities/nutrition-entity";

export type TNutritionListItem = {id:number, name:string, synonym:string};

export default class NutritionRepository extends RepositoryBase<NutritionEntity> {
  protected endpoint = "nutritions";
  protected ctor:new(data) => NutritionEntity = NutritionEntity;

  private cacheOfList:TNutritionListItem[]|null = null;

  public async list(withCache = true): Promise<TNutritionListItem[]> {
    if (withCache) {
      if (!!this.cacheOfList) return this.cacheOfList!;
    }

    const res = await this.get(this.endpoint + '/list');
    this.cacheOfList = res.data;

    return this.cacheOfList!;
  }
}
